import { Form, Formik } from "formik"
import React from "react"
import {
  getInputStyle,
  StyledEmailInput,
  StyledLabel,
  StyledNameInput,
  StyledNumberInput,
  StyledSelect,
  StyledSubmitButton,
  StyledSubmitButtonAlt,
  StyledTextInput,
  encode,
} from "../../assets/form-helper"

const inputStyle = getInputStyle()

export default function Oct23Form() {
  return (
    <Formik
      initialValues={{
        parent_first_name: "",
        parent_last_name: "",
        "parent-email": "",
        "event-children": "",
      }}
      onSubmit={(values, actions) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "oct-23-form", ...values }),
        })
          .then(() => {
            alert("Got it! Thanks!")
            actions.resetForm()
            // console.log(values)
          })
          .catch(() => {
            alert("Error")
          })
          .finally(() => actions.setSubmitting(false))
      }}
    >
      {() => (
        <Form name="oct-23-form" data-netlify="true">
          <input type="hidden" name="form-name" value="Oct-23-Form" />

          {/* <!--Parent Name--> */}
          <StyledNameInput
            label={"Enter your / parent name"}
            name="name"
            required={true}
            id="name"
            preId={"parent"}
            tint={"dark"}
          />

          {/* <!--Parent Email--> */}
          <StyledEmailInput
            name="parent-email"
            id="parent-email"
            label={"Enter your / parent email address"}
            tint={"dark"}
          />

          {/* <!--Number of Children--> */}
          <div className="my-4">
            <StyledLabel
              label={"Number of Children Attending"}
              labelFor={"event-children"}
              required={true}
              tint={"dark"}
            />
            <StyledTextInput
              id="event-children"
              name="event-children"
              label={"Enter number of children coming with you to event."}
              placeholder={"Enter a number"}
              required={true}
              tint={"dark"}
              className={inputStyle}
            />
          </div>

          {/* <!--Submit--> */}
          <StyledSubmitButtonAlt text={"Submit"} />
        </Form>
      )}
    </Formik>
  )
}
