/* This example requires Tailwind CSS v2.0+ */
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Oct23Form from "../components/forms/oct-23-form"
import SimpleMap from "../components/google-map"
import CustomInteriorPage from "../components/page-layouts/custom-interior-page"
import {
  PageH1Header,
  PageH2Header,
} from "../components/typography/page-headers"

function PageData() {
  return (
    <div>
      <div className="relative bg-coffee z-20 overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 relative">
          <div className="relative">
            <div className="relative max-w-4xl mx-auto py-32 px-8 z-30">
              <PageH1Header text="You're Invited!" tint={"light"} />
              <div className="md:w-2/3">
                <p className="text-off-white font-bold text-xl">
                  On October 14th 2023, BSA Scout Troop 146 will have a
                  recruiting event at Rita's Water Ice in Pottstown, PA. We will
                  be there from 3 - 7 PM, telling youth about our Troop and
                  answering parent questions.
                </p>
                <p className="text-off-white font-bold text-xl">
                  Every person who signs up that night gets free water ice.
                </p>
                <div className="block py-4">
                  <p className="text-off-white mb-2">
                    <span className="font-bold">Location: </span>
                    <a
                      href="https://maps.app.goo.gl/Q3HATJuCc9nWnq878"
                      target="_blank"
                      rel="noreferrer"
                      className={"text-light-blue"}
                    >
                      1350 N Charlotte St, Pottstown, PA 19464
                    </a>
                  </p>
                  <p className="text-off-white">
                    <span className="font-bold">Date & Time: </span>
                    October 14th, 2023 from 3 - 7 PM
                  </p>

                  <SimpleMap />
                </div>
              </div>
            </div>
            <div className="absolute inset-0 opacity-20 z-10">
              <StaticImage
                placeholder="dominantColor"
                src="../images/pottstown-riverfront-park.jpg"
                alt="Collage of different projects"
                layout="fixed"
                objectFit="contain"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            </div>
          </div>
          <div className="relative z-30">
            <div className="relative max-w-4xl mx-auto py-32 px-8 z-30">
              <PageH2Header text={"Register for the event!"} tint="light" />
              <p className="text-off-white text-xl">
                We'd love to know if you're coming so please fill out the form
                below:
              </p>
              <Oct23Form />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Oct23Page({ data }) {
  const siteTitle = data.site.siteMetadata.title

  return <CustomInteriorPage body={PageData()} seo={siteTitle} />
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
